<template>

	<div>

	<Header
		title="Berechnen"
		subtitle="Testen Sie Ihren Text auf Lesbarkeit"
	></Header>
	
  
	<v-container fluid white>
  	<v-container appwidth black--text>
		
		<v-row>		
			<v-col cols="12" sm="12" md="12" lg="12">
				<form>
					
					<v-textarea
						v-model="textinput"
						name="textinput"  
						filled
					></v-textarea>
						
					<v-btn rounded depressed class="mr-4" @click="clear">Löschen</v-btn>
					<v-btn rounded depressed color="primary" @click="submit">Berechnen</v-btn>
			  </form>
			<br/><br/>
			<div class="font-weight-black text-h5 text-sm-h4">Fleschindex: {{index.toFixed()}}</div>
			Sätze: {{sentences}} | Wörter: {{words}} | Silben: {{syllables}} | Diphthonge: {{diphthongs}} | Zeichen: {{characters}}
			
			</v-col>
		</v-row>

				
  	</v-container>
  	</v-container>
	<v-container fluid class="adsensebg">
	<v-container appwidth black--text>
		<v-row>		
			<v-col cols="12" sm="12" md="12" lg="12">
				<Adsense
					data-ad-client="ca-pub-9058756098063399"
					data-ad-slot="8646092862"
					data-ad-format="auto"
					data-full-width-responsive="true"
					>
				</Adsense>
			</v-col>
		</v-row>

  	</v-container>
  	</v-container>

	</div>

		
</template>
<style>

.adsensebg {
	background: #f5f5f5;
}

</style>


<script>


import Header from '@/components/Header';

export default {
  
		components: {
			Header,
		},
		data: () => ({
			name: '',
			textinput: 'Es war nicht einfach, aber sie kämpfte darum, mit der neuen Situation gut zurechtzukommen. Vor allem die extreme Höhe machte ihr zu schaffen. In der ersten Nacht am Berg hatte sie kein Auge zugemacht.',
			characters: 0,
			syllables: 0,
			diphthongs: 0,
			words: 0,
			index: 0,
			sentences: 0,
		}),


		methods: {
			submit () {
				this.fleschindex(this.textinput);
			},
			clear () {
				this.textinput = ''
				this.fleschindex(this.textinput);
				this.index = 0;
			},
		  
			fleschindex(text) {
		  
				text = text.trim();

				text = text.replace(/\s+/g,' ');
				
				text = text.toLowerCase();

				var searchRegExp = new RegExp('\\?\\!', 'g'); 
				text = text.replace(searchRegExp, '?');

				searchRegExp = new RegExp('\\!\\?', 'g'); 
				text = text.replace(searchRegExp, '?');
				
				searchRegExp = new RegExp('\\?\\?', 'g'); 
				text = text.replace(searchRegExp, '?');
				
				searchRegExp = new RegExp('\\?', 'g'); 
				text = text.replace(searchRegExp, '.');

				searchRegExp = new RegExp('\\!', 'g'); 
				text = text.replace(searchRegExp, '.');

				searchRegExp = new RegExp(':', 'g'); 
				text = text.replace(searchRegExp, '.');

				searchRegExp = new RegExp('[0-9]', 'g');
				text = text.replace(searchRegExp, '');
				
				searchRegExp = new RegExp(' . ', 'g'); 
				text = text.replace(searchRegExp, ' ');
				searchRegExp = new RegExp('%', 'g'); 
				text = text.replace(searchRegExp, ' ');

				searchRegExp = new RegExp('\\.\\.', 'g'); 
				text = text.replace(searchRegExp, '');

				// Words
				if (text.length > 0) {
					this.words = text.split(" ").length;
				} else {
					this.words = 0;
				}
			
				// Sentences
				searchRegExp = new RegExp('\\.', 'g'); 
				var punctuation = text.match(searchRegExp);
				if (punctuation) {
					this.sentences = punctuation.length;
				} else {
					this.sentences = 0;
				}
				
				// Abbreviations
				searchRegExp = new RegExp('( dr\\.)|( prof\\.)|( med\\.)|( bzw\\.)|( abb\\.)|( abb\\.)|( abschn\\.)|( alt\\.)|( anm\\.)|( aufl\\.)|( bd\\.)|( bsp\\.)|( bspw\\.)|( bzgl\\.)|( ca\\.)|( etc\\.)|( evtl\\.)|( f\\.)|( ff\\.)|( ggf\\.)|( hrsg\\.)|( inkl\\.)|( max\\.)|( min\\.)|( mio\\.)|( mrd\\.)|( nr\\.)|( pos\\.)|( rd\\.)|( s\\.)|( tab\\.)|( tel\\.)|( ae\\.)|( usw\\.)', 'g'); 
					
				var abbreviations = text.match(searchRegExp);
				
				if (abbreviations) {
					this.sentences = this.sentences - abbreviations.length;
				}

				searchRegExp = new RegExp('( d\\.h\\.)|( o\\.ae\\.)|( v\\.a\\.)|( s\\.u\\.)|( z\\.b\\.)|( z\\.h\\.)|( z\\.t\\.)', 'g'); 
				var abbreviations = text.match(searchRegExp);
				if (abbreviations) {
					this.sentences = this.sentences - abbreviations.length*2;
				}

				searchRegExp = new RegExp('( i\\.d\\.r\\.)', 'g'); 
				var abbreviations = text.match(searchRegExp);
				if (abbreviations) {
					this.sentences = this.sentences - abbreviations.length*3;
				}
	

				// Syllables
				searchRegExp = new RegExp('[aeiouöäü]', 'g');
				try {
					this.syllables = text.match(searchRegExp).length;
				} catch {
					this.syllables = 0;
				}
				
				// Diphthongs 
				searchRegExp = new RegExp('(eu)|(ei)|(ie)|(ai)|(au)|(äu)|(oi)', 'g');
				try {
					this.diphthongs = text.match(searchRegExp).length;
					this.syllables = this.syllables - this.diphthongs;
				} catch {
					this.diphthongs = 0;
				}
				
				// Characters 
				this.characters  = text.length;

				
				// Index
				try {
					if (this.sentences > 0) {
						this.index = 180 - (this.words / this.sentences) - (58.5 * this.syllables / this.words);
					}
				} catch {
					this.index = 0;
				}
				
				if(this.index > 100) {
					this.index = 100;
				}
				else if(this.index < 0) {
					this.index = 0;
				}
				
				
			},
			  
		},
	
	name: 'Berechnung',
		
	  
	head: {
		title: {
			inner: 'Berechnung',
			separator: '-',
			complement: 'Fleschindex'
		},
  
		link: [
			{
				rel: 'canonical',
				href: 'https://fleschindex.de/berechnen/',
			}
		],
		meta: [
			{
				name: 'description',
				content: 'Hier können Sie den Flesch-Index (Lesbarkeitsindex) online berechnen - kostenlos, ohne Anmeldung.',
			},
			{
				name: 'keywords',
				content: 'Flesch-Index, Lesbarkeitsindex, Reading-Ease"',
			},
			{
				property: 'og:title',
				content: 'Berechnung',
			},
			{
				property: 'og:description',
				content: 'Hier können Sie den Flesch-Index (Lesbarkeitsindex) online berechnen - kostenlos, ohne Anmeldung.',
			},
			{
				property: 'og:image',
				content: 'https://fleschindex.de/assets/img/fleschindex.jpg',
			},
			{
				property: 'og:url',
				content: 'https://fleschindex.de/berechnen/',
			},
		]
	 },
	}
</script>

