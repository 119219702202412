<template>
  	<v-container fluid primary>
		<v-container appwidth white--text>
			<v-row class="pt-md-12">		
				<v-col cols="12" sm="12" md="12" lg="12">
					<h1><span v-html="title"></span></h1>
					<h2 class="pb-md-10">{{ subtitle }}</h2>
				</v-col>
			</v-row>			
		</v-container>
  	</v-container>	
</template>


/************************************************/
/* SCRIPT */
/************************************************/

<script>
 

	export default {
	  name: "Header",
	  props: {
		title: {
		  type: String,
		  default: ""
		},
		subtitle: {
		  type: String,
		  default: ""
		},
	  }
	};
	
	
</script>


    